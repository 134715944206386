import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import styles from "./home.module.css";
import background from "../../video.mp4";
import Login from "../login";
import Register from "../register";

const Home = () => {
  return (
    <div className={styles.homeContainer}>
      <div className={styles.videoContainer}>
        <video src={background} muted autoPlay loop playsInline></video>
      </div>
      <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
};

export default Home;
