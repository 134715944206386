import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  isMobile,
  isAndroid,
  isIOS,
  isIOS13,
  isDesktop,
} from "react-device-detect";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Vimeo from "@u-wave/react-vimeo";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Webcam from "react-webcam";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import SupportChat from "../../components/chat";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styles from "./dashboard.module.css";
import loaderIcon from "../../images/loader.svg";
import {
  setCurrentUser,
  getCurrentUser,
  removeCurrentUser,
} from "../../services/authServices";
import signoutImg from "../../images/signout.png";
import chatIcon from "../../images/chat.png";
import leaderboardIcon from "../../images/leaderboard.png";
import pointsIcon from "../../images/points.png";
import tutorialsIcon from "../../images/tutorials.png";
import tutorialsDesktop from "../../images/tutorials-desktop.png";
import tutorialsMobile from "../../images/tutorials-mobile.png";
import pleaseRotate from "../../images/please-rotate.png";
import spinner from "../../images/spinner.svg";
import cameraFrame1 from "../../images/camera-frame-1.png";
import cameraFrame2 from "../../images/camera-frame-2.png";
import captureImg from "../../images/capture.png";
import retakeImg from "../../images/retake.png";
import downloadImg from "../../images/download.png";
import exitImg from "../../images/exit.png";
import { clear } from "@testing-library/user-event/dist/clear";

const Dashboard = () => {
  const navigate = useNavigate();
  const [webglLoaded, setWebglLoaded] = useState(false);
  const [webglUrl, setWebglUrl] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [isLeaderboard, setIsLeaderboard] = useState(false);
  const [isTutorials, setIsTutorials] = useState(false);
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [isLeaderboardLoaded, setIsLeaderboardLoaded] = useState(false);
  const [isActionButton, setIsActionButton] = useState(false);
  const [isSignoutButton, setIsSignoutButton] = useState(false);
  const [lobbyReady, setLobbyReady] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("");
  const [concertVideo, setConcertVideo] = useState(false);
  const [wickedVideo, setWickedVideo] = useState(false);
  const [concertVideoLive, setConcertVideoLive] = useState("");
  const [concertVideoVOD, setConcertVideoVOD] = useState("");
  const [wickedVideoLive, setWickedVideoLive] = useState("");
  const [wickedVideoVOD, setWickedVideoVOD] = useState("");
  const [videoActivatedTime, setVideoActivatedTime] = useState(false);
  const [videoSkipSeconds, setVideoSkipSeconds] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isVOD, setIsVOD] = useState(false);
  const [isChatBox, setIsChatBox] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [startQuiz, setStartQuiz] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [previousSelected, setPreviousSelected] = useState();
  const [totalQuestion, setTotalQuestion] = useState();
  const [quizWait, setQuizWait] = useState(false);
  const [loaderPercentage, setLoaderPercentage] = useState(0);
  const [isPhotobooth, setIsPhotobooth] = useState(false);
  const [photobooth, setPhotobooth] = useState("");
  const [photoboothType, setPhotoboothType] = useState();
  const [isCameraLoaded, setIsCameraLoaded] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("");
  const [isChatEnabled, setIsChatEnabled] = useState(true);
  const [interested, setInterested] = useState(false);
  const [interestPopup, setInterestPopup] = useState(false);
  const [userGender, setUserGender] = useState(false);
  const [concertVideoVolume, setConcertVideoVolume] = useState(0);
  const [concertVideoMuted, setConcertVideoMuted] = useState(true);
  const [wickedVideoVolume, setWickedVideoVolume] = useState(0);
  const [wickedVideoMuted, setWickedVideoMuted] = useState(true);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [isCountDown, setIsCountDown] = useState(false);

  const [totalScore, setTotalScore] = useState();
  const userId = JSON.parse(getCurrentUser())._id;

  let webglIframe = document.getElementById("webgl-iframe");
  const questionNo = useRef(0);
  let gender;
  const totalScoreRef = useRef(0);
  const [currentQuestion, setCurrentQuestion] = useState();
  const tutorialsRef = useRef(null);
  const newNotification = useRef(true);
  useEffect(() => {
    setInterested(JSON.parse(getCurrentUser()).interested);
    // window.gtag("config", "AW-303038762");
    // window.gtag("event", "conversion", {
    //   send_to: "AW-303038762/J9APCNacjuYDEKqCwJAB",
    // });

    // window.gtag("config", "DC-11962222");
    // window.gtag("event", "conversion", {
    //   allow_custom_scripts: true,
    //   send_to: "DC-11962222/raide0/dcm-r0+standard",
    // });

    // ReactGA.initialize("UA-245656363-1");
    // ReactGA.pageview(window.location.pathname + window.location.search);

    // const advancedMatching = { em: "" };
    // const options = {
    //   autoConfig: true,
    //   debug: false,
    // };
    // ReactPixel.init("1124829078045398", advancedMatching, options);
    // ReactPixel.track("lead", "/dashboard");
    console.log(JSON.parse(getCurrentUser()).gender);
    if (JSON.parse(getCurrentUser()).gender) {
      setUserGender(JSON.parse(getCurrentUser()).gender);
    }

    axios
      .get(process.env.REACT_APP_SERVER_URL + "/getVimeoData", {
        headers: {
          "moto-x-request-header":
            "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
        },
      })
      .then((response) => {
        setConcertVideoLive(response.data.result.concertLive);
        setConcertVideoVOD(response.data.result.concertVod);
        setWickedVideoLive(response.data.result.wickedLive);
        setWickedVideoVOD(response.data.result.wickedVod);
      })
      .catch((error) => {});

    axios
      .get(process.env.REACT_APP_SERVER_URL + "/getScreenChanger", {
        headers: {
          "moto-x-request-header":
            "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
        },
      })
      .then((response) => {
        setCurrentScreen(response.data.result.screenChanger);
        setVideoActivatedTime(response.data.result.updatedTime);
      })
      .catch((error) => {});

    let checkScreen = setInterval(() => {
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/getScreenChanger", {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        })
        .then((response) => {
          webglIframe.contentWindow.postMessage(
            JSON.stringify({
              screen: response.data.result.screenChanger,
              eventType: "screen",
              activatedTime: response.data.result.updatedTime,
            }),
            process.env.REACT_APP_WEBGL_URL
          );
        })
        .catch((error) => {});
    }, 20000);

    let webglController = setInterval(() => {
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/getEventControlData", {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        })
        .then((response) => {
          webglIframe.contentWindow.postMessage(
            JSON.stringify({
              eventType: "hotspot",
              experienceCenter: response.data.result.experienceCenterStatus,
              concert: response.data.result.concertStatus,
              wicked: response.data.result.wickedStatus,
            }),
            process.env.REACT_APP_WEBGL_URL
          );
        })
        .catch((error) => {});
    }, 20000);

    let countdownInterval;
    let checkNotification = setInterval(() => {
      axios
        .get(process.env.REACT_APP_SERVER_URL + "/getActiveNotification", {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        })
        .then((response) => {
          if (response.data.result) {
            if (newNotification.current) {
              newNotification.current = false;
              setIsNotification(true);
              setNotificationMessage(response.data.result.notification);

              if (response.data.result.notificationType === "countDownTimer") {
                setIsCountDown(true);
                const countDownDate = new Date(
                  response.data.result.eventTime
                ).getTime();
                let currentTime = new Date().getTime();
                countdownInterval = setInterval(() => {
                  currentTime = currentTime + 1000;
                  if (countDownDate > currentTime) {
                    countdownTimer(countDownDate - currentTime);
                  } else {
                    clearInterval(countdownInterval);
                  }
                }, 1000);
              } else {
                setIsCountDown(false);
              }
            }
          } else {
            newNotification.current = true;
            setIsNotification(false);
            clearInterval(countdownInterval);
            setIsCountDown(false);
          }
        })
        .catch((error) => {});
    }, 20000);

    let checkUserStatus = setInterval(() => {
      axios
        .get(
          process.env.REACT_APP_SERVER_URL +
            "/getUserBlockedStatus?userId=" +
            userId,
          {
            headers: {
              "moto-x-request-header":
                "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
            },
          }
        )
        .then((response) => {
          if (response.data.result.blocked) {
            logout();
          }
        })
        .catch((error) => {});
    }, 5000);

    axios
      .get(
        process.env.REACT_APP_SERVER_URL +
          "/getQuizQuestionList?userId=" +
          userId,
        {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        }
      )
      .then((response) => {
        setQuestionList(response.data.result);
        setTotalQuestion(response.data.result.length);
      })
      .catch((error) => {});

    if (getCurrentUser()) {
      setLobbyReady(true);
      if (isDesktop) {
        setDeviceType("pc");
      } else if (isAndroid) {
        setDeviceType("android");
      } else if (isIOS || isIOS13) {
        setDeviceType("ios");
      }
      setWebglUrl(
        process.env.REACT_APP_WEBGL_URL +
          "?name=" +
          JSON.parse(getCurrentUser()).name +
          "&mobileNo=" +
          JSON.parse(getCurrentUser()).mobileNo +
          "&deviceType=" +
          deviceType +
          "&screen=" +
          currentScreen +
          "&activatedTime=" +
          videoActivatedTime +
          "&gender=" +
          JSON.parse(getCurrentUser()).gender +
          "&avatar=" +
          JSON.parse(getCurrentUser()).avtar
      );
    } else {
      navigate("/");
    }

    const calculateScore = (time) => {
      if (time <= 600) {
        return 600 - time;
      } else {
        return 0;
      }
    };

    const handler = (event) => {
      if (event.origin === process.env.REACT_APP_EVENT_ORIGIN) {
        try {
          let gameType;
          let score;
          const data = JSON.parse(event.data);
          if (data.eventType == "loading") {
            setLoaderPercentage(data.progress * 100);
          } else if (data.eventType == "webgl-loaded") {
            setTimeout(() => {
              setWebglLoaded(true);
              setIsSignoutButton(true);
            }, 2000);
          } else if (data.eventType == "game-completed") {
            if (data.gameType === 1) {
              gameType = "maze";
              score = calculateScore(data.time);
            } else if (data.gameType === 2) {
              gameType = "parker";
              score = calculateScore(data.time);
            } else if (data.gameType === 3) {
              gameType = "photo";
              score = 100;
              setIsPhotobooth(true);
              setPhotobooth("camera");
              setIsChatEnabled(false);
              setIsChatBox(false);
              setPhotoboothType(2);
            } else if (data.gameType === 4) {
              gameType = "cocert";
              score = 100;
            } else if (data.gameType === 5) {
              gameType = "wicked";
              score = 100;
            } else if (data.gameType === 6) {
              gameType = "experiance center";
              score = 100;
            } else if (data.gameType === 7) {
              gameType = "configator";
              score = 100;
              setIsChatEnabled(false);
              setIsChatBox(false);
            } else if (data.gameType === 8) {
              gameType = "photo";
              score = 100;
              setIsPhotobooth(true);
              setPhotobooth("camera");
              setIsChatEnabled(false);
              setIsChatBox(false);
              setPhotoboothType(1);
            } else if (data.gameType === 9) {
              setIsChatEnabled(true);
            } else if (data.gameType === 12) {
              gameType = "avatar download";
              score = 100;

              window.dataLayer.push({
                event: "dashboard_download_avatar",
                eventCategory: "Dashboard",
                eventAction: "Download Avatar",
                "User ID": userId,
              });
            }

            let gameData = {
              gameType: gameType,
              score: score,
              userId: userId,
            };
            axios
              .post(process.env.REACT_APP_SERVER_URL + "/saveScore", gameData, {
                headers: {
                  "moto-x-request-header":
                    "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
                },
              })
              .then((response) => {})
              .catch((error) => {});
          } else if (data.eventType == "rpm-closed") {
            setIsSignoutButton(true);
          } else if (data.eventType == "enter-motoverse") {
            setIsActionButton(true);

            if (!localStorage.getItem("firstTimeuser")) {
              setTimeout(() => {
                setIsTutorials(true);
              }, 4000);
              setTimeout(() => {
                localStorage.setItem("firstTimeuser", "true");
              }, 6000);
            }
          } else if (data.eventType == "quiz") {
            playQuiz();
            setIsChatEnabled(false);
            setIsChatBox(false);
          } else if (data.eventType == "gender") {
            gender = data.gender;

            setUserGender(gender.toString());
          } else if (data.eventType == "avatar") {
            setUserGender(gender.toString());
            window.dataLayer.push({
              event: "dashboard_submit",
              eventCategory: "Dashboard",
              eventAction: "Submit",
              "User ID": userId,
            });

            axios
              .post(
                process.env.REACT_APP_SERVER_URL + "/updateAvtar",
                {
                  userId: userId,
                  avtar: data.avatar.toString(),
                  gender: gender.toString(),
                },
                {
                  headers: {
                    "moto-x-request-header":
                      "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
                  },
                }
              )
              .then((response) => {
                setCurrentUser({
                  _id: JSON.parse(getCurrentUser())._id,
                  name: JSON.parse(getCurrentUser()).name,
                  email: JSON.parse(getCurrentUser()).email,
                  mobileNo: JSON.parse(getCurrentUser()).mobileNo,
                  avtar: data.avatar.toString(),
                  gender: gender.toString(),
                  interested: JSON.parse(getCurrentUser()).interested,
                });
              })
              .catch((error) => {});
          }

          if (data.eventType === "video") {
            if (data.page === "concert") {
              setConcertVideoMuted(true);
              setConcertVideoVolume(0);
              let activatedTime = new Date(data.activatedTime).getTime();
              let currentTime = new Date().getTime();
              if (data.live) {
                setIsVOD(false);
                setVideoSkipSeconds((currentTime - activatedTime) / 1000);
              } else {
                setIsVOD(true);
                setVideoSkipSeconds(0);
              }
              setWickedVideo(false);
              setConcertVideo(true);
            }

            if (data.page === "wicked") {
              setWickedVideoMuted(true);
              setWickedVideoVolume(0);
              let activatedTime = new Date(data.activatedTime).getTime();
              let currentTime = new Date().getTime();
              if (data.live) {
                setIsVOD(false);
                setVideoSkipSeconds((currentTime - activatedTime) / 1000);
              } else {
                setIsVOD(true);
                setVideoSkipSeconds(0);
              }
              setConcertVideo(false);
              setWickedVideo(true);
            }
          }

          if (data.eventType === "video-off") {
            setVideoSkipSeconds(0);
            setConcertVideo(false);
            setWickedVideo(false);
            setVideoLoaded(false);

            setIsVOD(false);
          }

          if (data.eventType === "gameStarted") {
            setIsChatEnabled(false);
            setIsChatBox(false);
          }
        } catch (error) {}
      }
    };

    const handleClickOutside = (event) => {
      if (tutorialsRef.current) {
        setIsTutorials(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    window.addEventListener("message", handler);

    return () => {
      clearInterval(checkScreen);
      clearInterval(webglController);
      clearInterval(checkNotification);
      clearInterval(checkUserStatus);
      window.removeEventListener("message", handler);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [deviceType, currentScreen, videoActivatedTime, setQuestionList]);

  const zeroPad = (num, places) => {
    return String(num).padStart(places, "0");
  };

  const countdownTimer = (countDown) => {
    setHours(
      zeroPad(
        Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        2
      )
    );
    setMinutes(
      zeroPad(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)), 2)
    );
    setSeconds(zeroPad(Math.floor((countDown % (1000 * 60)) / 1000), 2));
  };

  const openLeaderboard = () => {
    setIsLeaderboard(true);
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/getLeaderBoardList", {
        headers: {
          "moto-x-request-header":
            "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
        },
      })
      .then((response) => {
        setIsLeaderboardLoaded(true);
        setLeaderboardList(response.data.result);
      })
      .catch((error) => {});
  };
  const logout = () => {
    removeCurrentUser();
    localStorage.removeItem("firstTimeuser");
    navigate("/");
  };

  const controlChatBox = () => {
    if (isChatBox) {
      setIsChatBox(false);
    } else {
      setIsChatBox(true);
    }
  };

  const playQuiz = () => {
    questionNo.current = 0;
    totalScoreRef.current = 0;
    setTotalScore(totalScoreRef.current);
    setCurrentQuestion(0);
    setStartQuiz(true);
  };

  const selectAnswer = (questionid, answerId, index) => {
    if (!quizWait) {
      setSelectedAnswer({
        answerId: answerId,
        questionId: questionid,
        answerIndex: index,
      });

      if (previousSelected >= 0) {
        document.getElementById(previousSelected).style.background =
          "transparent";
        document.getElementById(previousSelected).style.color = "#000000";
      }
      document.getElementById(index).style.background = "#000000";
      document.getElementById(index).style.color = "#ffffff";
      setPreviousSelected(index);
    }
  };

  const submitAnswer = (optionList) => {
    if (!quizWait) {
      if (selectedAnswer) {
        setQuizWait(true);
        let isCorrect = optionList.filter(function (e) {
          return e._id == selectedAnswer.answerId;
        })[0].correct;

        let correctAnswer = optionList.filter(function (e) {
          return e.correct == true;
        })[0].order;

        if (isCorrect) {
          document.getElementById(selectedAnswer.answerIndex).style.background =
            "#0ABF13";
          document.getElementById(selectedAnswer.answerIndex).style.color =
            "#ffffff";
          document.getElementById(
            selectedAnswer.answerIndex
          ).style.borderColor = "#0ABF13";

          totalScoreRef.current = totalScoreRef.current + 10;
          setTotalScore(totalScoreRef.current);
          let answerData = {
            userId: userId,
            questionObjectId: selectedAnswer.questionId,
            answerObjectId: selectedAnswer.answerId,
            score: 10,
          };

          axios
            .post(
              process.env.REACT_APP_SERVER_URL + "/saveQuizScore",
              answerData,
              {
                headers: {
                  "moto-x-request-header":
                    "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
                },
              }
            )
            .then((response) => {})
            .catch((error) => {});
        } else {
          document.getElementById(selectedAnswer.answerIndex).style.background =
            "#F82525";
          document.getElementById(selectedAnswer.answerIndex).style.color =
            "#ffffff";
          document.getElementById(
            selectedAnswer.answerIndex
          ).style.borderColor = "#F82525";

          document.getElementById(correctAnswer - 1).style.background =
            "#0ABF13";
          document.getElementById(correctAnswer - 1).style.color = "#ffffff";
          document.getElementById(correctAnswer - 1).style.borderColor =
            "#0ABF13";

          let answerData = {
            userId: userId,
            questionObjectId: selectedAnswer.questionId,
            answerObjectId: selectedAnswer.answerId,
            score: 0,
          };

          axios
            .post(
              process.env.REACT_APP_SERVER_URL + "/saveQuizScore",
              answerData,
              {
                headers: {
                  "moto-x-request-header":
                    "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
                },
              }
            )
            .then((response) => {})
            .catch((error) => {});
        }

        questionNo.current = questionNo.current + 1;

        setTimeout(() => {
          setCurrentQuestion(questionNo.current);
          setQuizWait(false);
          document.getElementById(selectedAnswer.answerIndex).style.background =
            "transparent";
          document.getElementById(selectedAnswer.answerIndex).style.color =
            "#000000";
          document.getElementById(
            selectedAnswer.answerIndex
          ).style.borderColor = "#000000";

          document.getElementById(correctAnswer - 1).style.background =
            "transparent";
          document.getElementById(correctAnswer - 1).style.color = "#000000";
          document.getElementById(correctAnswer - 1).style.borderColor =
            "#000000";

          if (questionNo.current === totalQuestion) {
            axios
              .post(
                process.env.REACT_APP_SERVER_URL + "/getQuizScore",
                {
                  userId: userId,
                  score: totalScoreRef.current,
                },
                {
                  headers: {
                    "moto-x-request-header":
                      "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
                  },
                }
              )
              .then((response) => {})
              .catch((error) => {});

            setTimeout(() => {
              setStartQuiz(false);
              webglIframe.contentWindow.postMessage(
                JSON.stringify({
                  eventType: "quiz",
                }),
                process.env.REACT_APP_WEBGL_URL
              );
              setIsChatEnabled(true);
            }, 3000);
          }
        }, 2000);
      }
    }
  };

  const handleFullScreen = useFullScreenHandle();

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    (data, photoGender) => {
      setPhotobooth("wait");
      let photoData;
      const imageSrc = webcamRef.current.getScreenshot({
        width: 1280,
        height: 720,
      });
      if (data === 1) {
        if (photoGender === "0") {
          photoData = {
            id: userId,
            imageData: imageSrc,
            frame: "frame-m.png",
          };
        } else {
          photoData = {
            id: userId,
            imageData: imageSrc,
            frame: "frame-f.png",
          };
        }
      } else {
        if (photoGender === "0") {
          photoData = {
            id: userId,
            imageData: imageSrc,
            frame: "male1.png",
          };
        } else {
          photoData = {
            id: userId,
            imageData: imageSrc,
            frame: "female1.png",
          };
        }
      }

      axios
        .post(process.env.REACT_APP_SERVER_URL + "/savePhotobooth", photoData, {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        })
        .then((response) => {
          setPhotobooth("done");

          setPhotoUrl(
            "https://s3.ap-south-1.amazonaws.com/tvs.photobooth/photobooth/" +
              response.data.photoName
          );
        })
        .catch((error) => {});
    },
    [webcamRef]
  );

  const fullscreenChanged = useCallback(
    (state, handle) => {
      if (isMobile) {
        if (!state) {
          setIsFullScreen(false);
        }
      }
    },
    [handleFullScreen]
  );

  const showInterest = () => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/updateInterestedStatus",
        {
          userId: userId,
          interested: true,
        },
        {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        }
      )
      .then((response) => {})
      .catch((error) => {});
    setInterested(true);
    setInterestPopup(true);
    setCurrentUser({
      _id: JSON.parse(getCurrentUser())._id,
      name: JSON.parse(getCurrentUser()).name,
      email: JSON.parse(getCurrentUser()).email,
      mobileNo: JSON.parse(getCurrentUser()).mobileNo,
      avtar: JSON.parse(getCurrentUser()).avtar,
      gender: JSON.parse(getCurrentUser()).gender,
      interested: true,
    });
  };

  if (!lobbyReady) {
    return null;
  }

  return (
    <FullScreen handle={handleFullScreen} onChange={fullscreenChanged}>
      <div className={styles.dashboardContainer}>
        <iframe
          src={webglUrl}
          frameBorder="0"
          allow="camera *; microphone *"
          id="webgl-iframe"
        ></iframe>
        {isSignoutButton && (
          <div className={styles.signOutContainer}>
            <img src={signoutImg} alt="SignOut" onClick={() => logout()} />
          </div>
        )}

        {isActionButton && (
          <Fragment>
            {/* {isChatEnabled && (
              <div
                className={styles.chatIconContainer}
                onClick={() => controlChatBox()}
              >
                <div>
                  <img src={chatIcon} alt="Chat" className={styles.icon} />
                </div>
              </div>
            )} */}

            <div className={styles.actionContainer}>
              {!interested && (
                <div className={styles.showInterest}>
                  <button onClick={() => showInterest()}>ENQUIRE NOW</button>
                </div>
              )}
              <div
                className={styles.actionBox}
                onClick={() => openLeaderboard()}
              >
                <div>
                  <img
                    src={leaderboardIcon}
                    alt="Leaderboard"
                    className={styles.icon}
                  />
                </div>
                <div className={styles.name}>LEADERBOARD</div>
              </div>
              <div
                className={styles.actionBox}
                onClick={() => {
                  setIsTutorials(true);
                }}
              >
                <div>
                  <img
                    src={tutorialsIcon}
                    alt="Tutorial"
                    className={styles.icon}
                  />
                </div>
                <div className={styles.name}>TUTORIAL</div>
              </div>
            </div>
          </Fragment>
        )}

        {interestPopup && (
          <div className={styles.showInterestContainer}>
            <div className={styles.showInterestBox}>
              <div
                className={styles.closeBtn}
                onClick={() => setInterestPopup(false)}
              >
                X
              </div>
              <div>Thank you, we will contact you shortly</div>
            </div>
          </div>
        )}

        {isLeaderboard && (
          <div className={styles.leaderboardContainer}>
            <div className={styles.leaderboardBox}>
              <div className={styles.header}>LEADERBOARD</div>
              <div
                className={styles.closeBtn}
                onClick={() => setIsLeaderboard(false)}
              >
                X
              </div>
              {!isLeaderboardLoaded && (
                <div className={styles.spinnerContainer}>
                  <img src={spinner} alt="Spinner" />
                </div>
              )}
              {isLeaderboardLoaded && (
                <div className={styles.leadboardTable}>
                  <div className={styles.leadboardTableHead}>
                    <div className={styles.rank}>RANK</div>
                    <div className={styles.name}>NAME</div>
                    <div className={styles.points}>POINTS</div>
                  </div>
                  {leaderboardList.map((item, index) => (
                    <div className={styles.leadboardTableBody} key={index}>
                      <div className={styles.rank}>{index + 1}</div>
                      <div className={styles.name}>{item.userData[0].name}</div>
                      <div className={styles.points}>{item.score}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {isTutorials && (
          <div className={styles.tutorialsContainer} ref={tutorialsRef}>
            {isDesktop && <img src={tutorialsDesktop} alt="Tutorials" />}
            {isMobile && <img src={tutorialsMobile} alt="Tutorials" />}
          </div>
        )}

        {!webglLoaded && (
          <div className={styles.loaderContainer}>
            <div>
              <div className={styles.loaderProgressBar}>
                <CircularProgressbar
                  value={loaderPercentage}
                  text={`${parseInt(loaderPercentage)}%`}
                />
              </div>
              <div className={styles.loaderText}>
                Please be patient, this may take a while
              </div>
            </div>
          </div>
        )}

        {concertVideo && (
          <div className={styles.videoContainer}>
            {concertVideoMuted && videoLoaded && (
              <div
                className={styles.unmuteContainer}
                onClick={() => {
                  setConcertVideoMuted(false);
                  setConcertVideoVolume(1);
                }}
              >
                <div className={styles.unmuteButton}>🔊 UNMUTE AUDIO</div>
              </div>
            )}

            {!isVOD && (
              <div>
                <Vimeo
                  video={concertVideoLive}
                  start={videoSkipSeconds}
                  playsInline={true}
                  autoplay={true}
                  controls={isVOD}
                  volume={concertVideoVolume}
                  muted={concertVideoMuted}
                  onReady={() => {
                    setVideoLoaded(true);
                  }}
                />
              </div>
            )}

            {isVOD && (
              <div>
                <Vimeo
                  video={concertVideoVOD}
                  start={videoSkipSeconds}
                  playsInline={true}
                  autoplay={true}
                  controls={isVOD}
                  volume={concertVideoVolume}
                  muted={concertVideoMuted}
                  onReady={() => {
                    setVideoLoaded(true);
                  }}
                />
              </div>
            )}

            {isVOD && (
              <div
                className={styles.backVideo}
                onClick={() => {
                  setVideoSkipSeconds(0);
                  setConcertVideo(false);
                  setVideoLoaded(false);
                  setIsVOD(false);
                  webglIframe.contentWindow.postMessage(
                    JSON.stringify({
                      eventType: "video-close",
                      video: "concert",
                    }),
                    process.env.REACT_APP_WEBGL_URL
                  );
                }}
              >
                ← BACK
              </div>
            )}

            {!videoLoaded && (
              <div className={styles.spinnerContainer}>
                <img src={spinner} alt="Spinner" />
              </div>
            )}
          </div>
        )}

        {wickedVideo && (
          <div className={styles.videoContainer}>
            {wickedVideoMuted && videoLoaded && (
              <div
                className={styles.unmuteContainer}
                onClick={() => {
                  setWickedVideoMuted(false);
                  setWickedVideoVolume(1);
                }}
              >
                <div className={styles.unmuteButton}>🔊 UNMUTE AUDIO</div>
              </div>
            )}
            {!isVOD && (
              <div>
                <Vimeo
                  video={wickedVideoLive}
                  start={videoSkipSeconds}
                  playsInline={true}
                  autoplay={true}
                  controls={isVOD}
                  volume={wickedVideoVolume}
                  muted={wickedVideoMuted}
                  onReady={() => {
                    setVideoLoaded(true);
                  }}
                />
              </div>
            )}
            {isVOD && (
              <div>
                <Vimeo
                  video={wickedVideoVOD}
                  start={videoSkipSeconds}
                  playsInline={true}
                  autoplay={true}
                  controls={isVOD}
                  volume={wickedVideoVolume}
                  muted={wickedVideoMuted}
                  onReady={() => {
                    setVideoLoaded(true);
                  }}
                />
              </div>
            )}

            {isVOD && (
              <div
                className={styles.backVideo}
                onClick={() => {
                  setVideoSkipSeconds(0);
                  setWickedVideo(false);
                  setVideoLoaded(false);
                  setIsVOD(false);
                  webglIframe.contentWindow.postMessage(
                    JSON.stringify({
                      eventType: "video-close",
                      video: "wicked",
                    }),
                    process.env.REACT_APP_WEBGL_URL
                  );
                }}
              >
                ← BACK
              </div>
            )}

            {!videoLoaded && (
              <div className={styles.spinnerContainer}>
                <img src={spinner} alt="Spinner" />
              </div>
            )}
          </div>
        )}
        {isChatBox && (
          <div className={styles.chatBoxContainer}>
            <div className={styles.header}>
              <div className={styles.title}>CHAT</div>
              <div
                onClick={() => controlChatBox()}
                className={styles.chatClose}
              >
                X
              </div>
            </div>
            <SupportChat />
          </div>
        )}
        <div className={styles.pleaseRotateContainer}>
          <div>
            <div>
              <img src={pleaseRotate} alt="Please rotate" />
            </div>
            <div>
              <div className={styles.header}>Please rotate your device</div>
              <div className={styles.subHeader}>
                We support landscape mode only
              </div>
            </div>
          </div>
        </div>
        {!isIOS && !isFullScreen && (
          <div className={styles.fullscreenSwitchContainer}>
            <div>
              <div className={styles.instruction}>
                For best experience please switch to Full screen mode
              </div>
              <div className={styles.btnContainer}>
                <div
                  className={styles.ok}
                  onClick={() => {
                    handleFullScreen.enter();
                    setIsFullScreen(true);
                  }}
                >
                  FULLSCREEN
                </div>
                {isDesktop && (
                  <div
                    className={styles.cancel}
                    onClick={() => {
                      setIsFullScreen(true);
                    }}
                  >
                    CANCEL
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {startQuiz && (
          <div className={styles.quizSection}>
            <div className={styles.quizContainer}>
              {currentQuestion < totalQuestion && (
                <div className={styles.quizBox}>
                  <div className={styles.headerContainer}>
                    <div>
                      <span className={styles.currentQuesStatus}>
                        Question {currentQuestion + 1}
                      </span>
                      <span className={styles.totalQuestion}>
                        /{totalQuestion}
                      </span>
                    </div>
                    <div
                      className={styles.closeBtn}
                      onClick={() => {
                        setStartQuiz(false);
                        setIsChatEnabled(true);
                      }}
                    >
                      X
                    </div>
                  </div>
                  <div className={styles.questionContainer}>
                    {questionList[currentQuestion].question}
                  </div>
                  {questionList[currentQuestion].optionData.map(
                    (item, index) => (
                      <div
                        id={index}
                        className={styles.optionBox}
                        key={index}
                        onClick={() =>
                          selectAnswer(
                            questionList[currentQuestion]._id,
                            item._id,
                            index
                          )
                        }
                        style={{
                          background: item.background,
                          color: item.textColor,
                          borderColor: item.borderColor,
                        }}
                      >
                        {item.option}
                      </div>
                    )
                  )}

                  <div className={styles.buttonContainer}>
                    <button
                      onClick={() =>
                        submitAnswer(questionList[currentQuestion].optionData)
                      }
                      disabled={quizWait}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              )}

              {currentQuestion === totalQuestion && (
                <div className={styles.quizResultContainer}>
                  <div>
                    {totalScore >= 0 && totalScore < 30 && (
                      <div className={styles.header}>BETTER LUCK NEXT TIME</div>
                    )}
                    {totalScore >= 30 && (
                      <div className={styles.header}>CONGRATULATIONS!</div>
                    )}
                    <div className={styles.score}>
                      {totalScore}/{totalQuestion * 10}
                    </div>
                    <div className={styles.youScored}>You Scored</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {isPhotobooth && (
          <div className={styles.photoboothContainer}>
            {!isCameraLoaded && (
              <div className={styles.cameraWait}>
                <div>
                  <div>
                    <img src={spinner} alt="Spinner" />
                  </div>
                  <div>Camera is loading, please wait...</div>
                </div>
              </div>
            )}
            {photobooth === "camera" && (
              <div>
                <div>
                  <Webcam
                    audio={false}
                    height={720}
                    screenshotFormat="image/jpeg"
                    width={1280}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    onUserMedia={() => {
                      setIsCameraLoaded(true);
                    }}
                    onUserMediaError={() => {
                      setIsPhotobooth(false);
                      setPhotobooth("");
                      setIsChatEnabled(true);
                    }}
                  />
                </div>

                <div className={styles.cameraFrame}>
                  {userGender === "0" && (
                    <img src={cameraFrame1} alt="Camera Frame" />
                  )}
                  {userGender === "1" && (
                    <img src={cameraFrame2} alt="Camera Frame" />
                  )}
                </div>
                <div className={styles.cameraControls}>
                  <div className={styles.capture}>
                    <img
                      src={captureImg}
                      alt="Capture"
                      onClick={() => capture(photoboothType, userGender)}
                    />
                  </div>
                </div>
              </div>
            )}

            {photobooth === "wait" && (
              <div className={styles.cameraWait}>
                <div>
                  <div>
                    <img src={spinner} alt="Spinner" />
                  </div>
                  <div>Please be patient, this may take a while</div>
                </div>
              </div>
            )}

            {photobooth === "done" && (
              <div className={styles.cameraOutput}>
                <div>
                  <img
                    src={photoUrl}
                    alt="Output"
                    className={styles.outputImg}
                  />
                </div>
                <div className={styles.cameraControlsO}>
                  <div className={styles.retake}>
                    <img
                      src={retakeImg}
                      alt="Retake"
                      onClick={() => setPhotobooth("camera")}
                    />
                  </div>
                  <div className={styles.download}>
                    <a
                      href={photoUrl}
                      rel="noopener noreferrer"
                      target={"_blank"}
                    >
                      {" "}
                      <img src={downloadImg} alt="Downlaod" />
                    </a>
                  </div>
                </div>
              </div>
            )}

            <div className={styles.photoboothExit}>
              <img
                src={exitImg}
                alt="Exit"
                onClick={() => {
                  setIsPhotobooth(false);
                  setPhotobooth("");
                  setIsChatEnabled(true);
                }}
              />
            </div>
          </div>
        )}

        {isNotification && (
          <div className={styles.notificationContainer}>
            <div className={styles.message}>{notificationMessage}</div>
            {isCountDown && (
              <div className={styles.countdown}>
                {hours}:{minutes}:{seconds}
              </div>
            )}
          </div>
        )}
      </div>
    </FullScreen>
  );
};

export default Dashboard;
