import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import ReactGA from "react-ga";
import axios from "axios";
import { getCurrentUser } from "../../services/authServices";
import styles from "./walkthrough.module.css";

// import motoverseVideo from "../../tvs-motoverse.mp4";
import pleaseRotate from "../../images/please-rotate.png";
const Walkthrough = () => {
  const userId = JSON.parse(getCurrentUser())._id;
  const navigate = useNavigate();
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [isLandscape, setIsLandscape] = useState(false);
  const [walkthroughVideo, setwalkthroughVideo] = useState("");
  const [volume, setVolume] = useState(0);
  const [isVideoReady, setIsVideoReady] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/getVimeoData", {
        headers: {
          "moto-x-request-header":
            "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
        },
      })
      .then((response) => {
        setwalkthroughVideo(response.data.result.walkthrough);
      })
      .catch((error) => {});
    // ReactGA.initialize("UA-245656363-1");
    // ReactGA.pageview(window.location.pathname + window.location.search);
    if (window.innerWidth > window.innerHeight) {
      setIsLandscape(true);
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth > window.innerHeight) {
        setIsLandscape(true);
      }
    });
  }, [isLandscape]);
  const muteUnmuteVideo = () => {
    setIsVideoMuted(!isVideoMuted);
    setVolume(1);
    window.dataLayer.push({
      event: "walkthrough_unmute_audio",
      eventCategory: "Walkthrough",
      eventAction: "Unmute audio",
      "User ID": userId,
    });
  };

  if (!isLandscape) {
    return (
      <div className={styles.pleaseRotateContainer}>
        <div>
          <div>
            <img src={pleaseRotate} alt="Please rotate" />
          </div>
          <div>
            <div className={styles.header}>Please rotate your device</div>
            <div className={styles.subHeader}>
              We support landscape mode only
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.walkthroughContainer}>
      {isVideoReady && isVideoMuted && (
        <div
          className={styles.unmuteContainer}
          onClick={() => muteUnmuteVideo()}
        >
          <div className={styles.unmuteButton}>🔊 UNMUTE AUDIO</div>
        </div>
      )}

      {!isVideoReady && (
        <div className={styles.loading}>Loading, please wait...</div>
      )}

      {isVideoReady && (
        <div
          className={styles.skipContainer}
          onClick={() => {
            navigate("/dashboard");
            window.dataLayer.push({
              event: "walkthrough_skip",
              eventCategory: "Walkthrough",
              eventAction: "Skip clicked",
              "User ID": userId,
            });
          }}
        >
          SKIP &gt;|
        </div>
      )}

      <div>
        <ReactPlayer
          url={walkthroughVideo}
          width={"100vw"}
          height={"100vh"}
          controls={false}
          playing={true}
          muted={isVideoMuted}
          volume={volume}
          pip={false}
          playsinline={true}
          onReady={() => {
            setIsVideoReady(true);
          }}
          onEnded={() => {
            navigate("/dashboard");
          }}
        ></ReactPlayer>
      </div>

      <div className={styles.pleaseRotateContainer}>
        <div>
          <div>
            <img src={pleaseRotate} alt="Please rotate" />
          </div>
          <div>
            <div className={styles.header}>Please rotate your device</div>
            <div className={styles.subHeader}>
              We support landscape mode only
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Walkthrough;
