import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import styles from "./privacy.module.css";

const Privacy = () => {
  return (
    <div className={styles.privacyContainer}>
      <p className={styles.header}>Privacy Policy - TVS Motoverse</p>
      <p>
        Your privacy is of paramount importance to us. We are committed to
        safeguarding your privacy and protecting your Personal Data that is with
        us. This Privacy Policy outlines the details of the Personal Data we
        collect and process, how we handle it and the purposes for which we use
        it. Please read the following carefully to understand our practices
        regarding your Personal Data.
      </p>
      <p>
        Throughout this document, the terms “we”, “us”, “our” & “ours” refer to
        TVS Motors. And the terms “you”, “your” & “yours” refer to YOU (the
        individual whose Personal Data we are referring to).
      </p>
      <p>
        <b>What Personal Data do we collect, store and process?</b>
        <div>
          Categories of Personal Data that we collect, store and process are as
          follows:
        </div>
        <ul>
          <li>
            Online Identifiers and other Technical Data (for e.g., IP address,
            browser type, device identifiers)
          </li>
          <li>
            Personal Data collected via permissions on our mobile applications
            (for e.g., camera, location data , storage, photos and SMS)
          </li>
          <li>
            Communications details (for e.g., communication done through emails)
          </li>
          <li>Generated Data (for e.g., logs, transaction records)</li>
          <li>Information relevant to surveys that we undertake.</li>
          <li>Testimonials that may contain some Personal Data.</li>
        </ul>
      </p>
      <p>
        <b>Where do we collect your Personal Data from?</b>
        <div>We collect your Personal Data in the following ways:</div>
        <ul>
          <li>
            When you visit our website or social media pages and fill in the
            registration form and use the Contact Us facility.
          </li>
          <li>When you use our mobile applications.</li>
          <li>
            When you interact with us via our websites or use services on our
            websites including customer support.
          </li>
          <li>When you interact with our dealers. </li>
        </ul>
      </p>
      <p>
        <b>How do we use your Personal Data?</b>
        <div>We use your Personal Data for the following purposes:</div>
        <ul>
          <li>
            If you express an interest in our services, we collect and share
            your Personal Data with our dealer, who in turn would contact you to
            schedule a store visit.
          </li>
          <li>
            If you are participant in the launch event, we collect your Personal
            Data for the following purposes:
            <ul>
              <li>
                to authenticate your account or information on our applications
                that you use.
              </li>
              <li>to provide you with our products and services</li>
              <li>to cross sell vehicles on our app/website</li>
              <li>
                to communicate with you regarding existing products and services
                availed by you, including notifications of any alerts or
                updates.
              </li>
              <li>to evaluate, develop and improve our services.</li>
              <li>for market and product analysis and market research.</li>
              <li>
                to send you information about our other products or services
                which may be of interest to you.
              </li>
              <li>to obtain feedback and handle enquiries and complaints.</li>
              <li>to comply with legal or regulatory requirements.</li>
            </ul>
          </li>
          <li>
            If you are a website visitor, we process your Personal Data to
            optimize your website experience and customize content.
          </li>
        </ul>
      </p>
      <p>
        <b>Who do we share your Personal Data with?</b>
        <div>We may disclose your Personal Data to:</div>
        <ul>
          <li>Our Parent Company for business and operational purposes</li>
          <li>Our affiliates or group companies</li>
          <li>Our Dealer and/or</li>
          <li>
            Third Party Service Providers who work for us or provide services or
            products to us.
          </li>
        </ul>
      </p>
      <p>
        <b>
          We may also share your Personal Data under the following
          circumstances:
        </b>
        <ul>
          <li>
            To respond to court orders, or legal process, or to establish our
            legal rights or defend against legal claims.
          </li>
          <li>if we are acquired by or merged with another company.</li>
        </ul>
      </p>
      <p>
        <b>International data transfer</b>
        <div>
          The data collected from you is stored in India We may transfer your
          Personal Data to other countries outside your country of residence for
          any of the purposes defined in this Privacy Policy to the entities
          mentioned above. Any Personal Data that we transfer will be protected
          in accordance with this Privacy Policy.
        </div>
      </p>
      <p>
        <b>How do we secure your Personal Data?</b>
        <div>
          We are committed to protecting your Personal Data in our custody. We
          take reasonable steps to ensure that appropriate physical, technical
          and managerial safeguards are in place to protect your Personal Data
          from unauthorized access, alteration, transmission, and deletion. We
          train our employees about the importance of maintaining the privacy
          and security of your Personal Data. We ensure that the third parties
          with whom we share your Personal Data under appropriate contracts;
          take appropriate security measures to protect your Personal Data in
          line with our policies.
        </div>
      </p>
      <p>
        <b>How long do we keep your Personal Data?</b>
        <div>
          We retain your Personal Data for as long as it is required to fulfil
          the purposes outlined in this Privacy Policy and for legal or
          regulatory reasons.
        </div>
      </p>
      <p>
        <b>How do we use cookies and other tracking mechanisms?</b>
        <div>
          We use cookies and other tracking mechanisms on our website to collect
          data about you. We use the data collected from cookies and trackers to
          analyze trends and statistics. This will help us optimize and
          customize your website experience and to provide better website
          functionalities. We collect Personal Data about you via Mobile
          Applications using permissions such as camera contacts/telephone,
          location, photo, SMS, etc. Your iOS and Android devices will notify
          you of the permissions that our app seeks and will provide you an
          option to consent to or refuse the permission. We use the data
          obtained through permissions to provide the functionalities through
          the Mobile Applications.
        </div>
      </p>
      <p>
        <b>Links to other websites</b>
        <div>
          Our website may contain links to websites of other organisations. This
          Privacy Policy does not cover how that organisation processes Personal
          Data. We encourage you to read the Privacy policies of the other
          websites you visit.
        </div>
      </p>
      <p>
        <b>How do we keep this Policy up to date?</b>
        <div>
          We regularly review and update our Privacy Policy to ensure it is
          up-to-date and accurate. Any changes we may make to this Privacy
          Policy in the future will be posted on this page. When we post changes
          to this Privacy Policy, we will revise the “last updated” date.
        </div>
      </p>
      <p>
        <b>Limitation of liability</b>
        <div>
          To the extent permissible under the law, we shall not be liable for
          any indirect, incidental, special, consequential or exemplary damages,
          including but not limited to, damages for loss of profits, goodwill,
          data, information, or other intangible losses (even if we have been
          advised of the possibility of such damages), arising out of this
          Privacy Policy.
        </div>
      </p>
      <p>
        <b>Governing law, jurisdiction and dispute resolution</b>
        <div>
          This Privacy Policy shall be construed and governed by the laws of
          India without regard to principles of conflict of laws. All disputes,
          differences, claims, controversies and the like, which may arise
          between the parties to this Privacy Policy, including construction,
          meaning or operation or effect of the same shall be referred to sole
          arbitration of an arbitrator to be nominated by the MCCI Arbitration,
          Mediation and Conciliation Centre (MAMC), The Madras Chamber of
          Commerce and Industry, ‘Karumuttu Centre’, First Floor, No. 634, Anna
          Salai, Nandanam, Chennai – 600 035, at the request of the authorised
          signatory of either TVS Motor or yourself, as the case may be, and
          such arbitration shall be conducted in accordance with the provisions
          of the Arbitration and Conciliation (Amendment) Act, 2021, or its
          statutory amendments, and in accordance with the MAMC’s Rules of
          Arbitration and Conciliation or its amendments, in English language,
          and the seat of arbitration shall be at Chennai; the venue of
          arbitration shall be as per the choice of the MAMC-appointed
          arbitrator. The Award passed by the Arbitrator shall be final and
          binding on both the Parties.
        </div>
      </p>
      <p>
        <b>How do you contact us?</b>
        <div>
          For any further queries and complaints related to privacy you could
          reach our Data Protection Officer, Mr. P Nagarajkanna at the following
          address
        </div>
        <div>
          Email: <a href="mailto:dpo@tvsmotor.com">dpo@tvsmotor.com</a>
        </div>
        <div>
          Business Address: Post Box No 4, Harita, Hosur, Tamil Nadu 635109
        </div>
        <div>Phone : 04344-276780</div>
      </p>
      <p>Last updated on 22 August 2022</p>
    </div>
  );
};

export default Privacy;
