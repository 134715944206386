import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Home from "./pages/home";
import Dashboard from "./pages/dashboard";
import Walkthrough from "./pages/walkthrough";
import Privacy from "./pages/privacy";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/*" element={<Home />}></Route>
          <Route path="/walkthrough" element={<Walkthrough />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
