let getCurrentUser = () => {
  return localStorage.getItem("currentUser");
};

let setCurrentUser = (user) => {
  localStorage.setItem("currentUser", JSON.stringify(user));
};

let removeCurrentUser = (user) => {
  localStorage.removeItem("currentUser");
};

export { getCurrentUser, setCurrentUser, removeCurrentUser };
