import React, { useEffect, useState, useRef, Fragment } from "react";
import SimpleBarReact from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import styles from "./chat.module.css";
import send from "../../images/send.png";
import io from "socket.io-client";
import moment from "moment";
import axios from "axios";
import { getCurrentUser } from "../../services/authServices";

const Chat = () => {
  const [messageList, setMessageList] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const scrollableNodeRef = useRef(null);
  const userId = JSON.parse(getCurrentUser())._id;
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    let connectSocket = io(process.env.REACT_APP_SOCKET_URL);
    setSocket(connectSocket);
    connectSocket.on("receive_chat_message", (data) => {
      setMessageList((prevArray) => [...prevArray, data]);
      setTimeout(() => {
        scrollableNodeRef.current.scrollTo({
          top: scrollableNodeRef.current.scrollHeight + 100,
          behavior: "smooth",
        });
      }, 500);
    });

    axios
      .get(process.env.REACT_APP_SERVER_URL + "/getAllChatMessages", {
        headers: {
          "moto-x-request-header":
            "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
        },
      })
      .then((response) => {
        setMessageList(response.data.result);
        setTimeout(() => {
          scrollableNodeRef.current.scrollTo({
            top: scrollableNodeRef.current.scrollHeight + 100,
            behavior: "smooth",
          });
        }, 500);
      })
      .catch((error) => {});
    return () => {
      try {
        connectSocket.off();
      } catch (error) {}
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitMessage();
    }
  };

  let fullName = JSON.parse(getCurrentUser()).name.split(" ");

  const submitMessage = () => {
    socket.emit("send_chat_message", {
      username:
        fullName[0] +
        " @" +
        JSON.parse(getCurrentUser()).mobileNo.substr(
          JSON.parse(getCurrentUser()).mobileNo.length - 5
        ),
      senderId: userId,
      message: userMessage,
    });

    setMessageList((prevArray) => [
      ...prevArray,
      {
        senderId: userId,
        username:
          fullName[0] +
          " @" +
          JSON.parse(getCurrentUser()).mobileNo.substr(
            JSON.parse(getCurrentUser()).mobileNo.length - 5
          ),
        message: userMessage,
        created_at: new Date(),
      },
    ]);
    setUserMessage("");
    setTimeout(() => {
      scrollableNodeRef.current.scrollTo({
        top: scrollableNodeRef.current.scrollHeight + 100,
        behavior: "smooth",
      });
    }, 500);
  };
  return (
    <div className={styles.chatComponent}>
      <SimpleBarReact
        style={{ maxHeight: "23vw", minHeight: "23vw" }}
        scrollableNodeProps={{ ref: scrollableNodeRef }}
      >
        <div className={styles.body}>
          {messageList.map((item, index) => (
            <Fragment key={index}>
              {item.senderId !== userId && (
                <Fragment>
                  <div className={styles.rightMessage} key={index}>
                    <div className={styles.message}>{item.message}</div>
                    <div className={styles.msgDetails}>
                      <div className={styles.username}>{item.username}</div>
                      <div className={styles.timestamp}>
                        {moment(item.created_at)
                          .utcOffset("+05:30")
                          .format("DD-MMM-yyyy HH:mm A")}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              {item.senderId === userId && (
                <Fragment>
                  <div className={styles.leftMessage}>
                    <div className={styles.message}>{item.message}</div>
                    <div className={styles.msgDetails}>
                      <div className={styles.username}>You</div>
                      <div className={styles.timestamp}>
                        {moment(item.created_at)
                          .utcOffset("+05:30")
                          .format("DD-MMM-yyyy HH:mm A")}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          ))}
        </div>
      </SimpleBarReact>
      <div className={styles.inputBoxContainer}>
        <div>
          <input
            type="text"
            placeholder="Type your message"
            onChange={(env) => {
              setUserMessage(env.target.value);
            }}
            value={userMessage}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className={styles.sendContainer}>
          <img src={send} alt="Send" onClick={() => submitMessage()} />
        </div>
      </div>
    </div>
  );
};

export default Chat;
