import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { Country, State } from "country-state-city";
import { toast } from "react-toastify";
import { setCurrentUser, removeCurrentUser } from "../../services/authServices";
import axios from "axios";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { isMobile } from "react-device-detect";
import { useCookies } from "react-cookie";
import styles from "./register.module.css";
import motoverseImg from "../../images/motoverse.png";
import tvsImg from "../../images/tvs.png";
import wickedImg from "../../images/wicked.png";

const Register = () => {
  const navigate = useNavigate();
  const [submitValue, setSubmitValue] = useState("Submit");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disableOTP, setDisableOTP] = useState(true);
  const [otpStatus, setOTPStatus] = useState("Get OTP");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [registrationDone, setRegistrationDone] = useState(false);
  const [isCountryCode, setIsCountryCode] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);

  useEffect(() => {
    // ReactGA.initialize("UA-245656363-1");
    // ReactGA.pageview(window.location.pathname + window.location.search);
    // const advancedMatching = { em: "" };
    // const options = {
    //   autoConfig: true,
    //   debug: false,
    // };
    // ReactPixel.init("1124829078045398", advancedMatching, options);
    // ReactPixel.pageView();

    setCountryList(Country.getAllCountries());
    removeCurrentUser();
    localStorage.removeItem("firstTimeuser");
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setSubmitValue("Please wait...");
    if (registrationDone) {
      const logindata = {
        mobileNo: data.mobileNo,
        otp: data.otp,
      };
      login(logindata);
    } else {
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/registerParticipant", data, {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        })
        .then((response) => {
          setRegistrationDone(true);
          setSubmitValue("Submit");
          setIsSubmitting(false);
        })
        .catch((error) => {
          window.dataLayer.push({
            event: "home_submit",
            eventCategory: "Registration",
            eventAction: "Submit",
            eventLabel: "fail",
            "error message": "Sorry! You are already registered with us.",
          });

          toast.error("Sorry! You are already registered with us.", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          setSubmitValue("Submit");
          setIsSubmitting(false);
        });
    }
  };

  const login = (data) => {
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/participantLogin", data, {
        headers: {
          "moto-x-request-header":
            "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
        },
      })
      .then((response) => {
        setCurrentUser(response.data.userData);
        navigate("/dashboard");
        toast.success("You have successfully logged in", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });

        window.dataLayer.push({
          event: "home_submit",
          eventCategory: "Registration",
          eventAction: "Submit",
          eventLabel: "success",
          "User ID": response.data.userData._id,
        });

        setCookie("name", response.data.userData.name, { path: "/" });
        setCookie("mobile", response.data.userData.mobileNo, {
          path: "/",
        });
        setCookie("email", response.data.userData.email, { path: "/" });
      })
      .catch((error) => {
        if (error.response.status === 417) {
          toast.error("Sorry! Invalid OTP", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        } else if (error.response.status === 401) {
          toast.error("Sorry! You're not registered with us.", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
        setIsSubmitting(false);
        setSubmitValue("Submit");
      });
  };
  const sendOTP = () => {
    setDisableOTP(true);
    setOTPStatus("Processing");
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/sendOTPToUser",
        {
          mobileNo: phoneNumber,
        },
        {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          setOTPStatus("Get OTP");
          setDisableOTP(false);
        }, 10000);
        setOTPStatus("✓ Sent");
      })
      .catch((error) => {
        setOTPStatus("Get OTP");
        setDisableOTP(false);
        toast.error("Sorry! You're not registered with us.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };
  return (
    <div className={styles.registerContainer}>
      <div className={styles.tvsContainer}>
        <img src={tvsImg} alt="TVS" />
      </div>
      <div className={styles.wickedContainer}>
        <img src={wickedImg} alt="Wicked" />
      </div>
      <div className={styles.boxContainer}>
        <div>
          <div className={styles.motoverseContainer}>
            <img src={motoverseImg} alt="Motoverse" />
          </div>
          <div className={styles.formContainer}>
            <div className={styles.switchContainer}>
              <div className={styles.switchBox}>REGISTER</div>
              <div
                className={styles.switchBoxDisabled}
                onClick={() => navigate("/login")}
              >
                LOGIN
              </div>
            </div>
            <div className={styles.formBox}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formGroup}>
                  <div className={styles.inputLabel}>Full Name *</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="name"
                      disabled={registrationDone}
                      {...register("name", {
                        required: "Please enter your Full Name",
                      })}
                    />
                  </div>
                  {errors.fullName && (
                    <div className={styles.errorMsg}>
                      * {errors.name.message}
                    </div>
                  )}
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.inputLabel}>Phone Number *</div>
                  <div className={styles.postionRelative}>
                    <input
                      id="mobileInput"
                      type="text"
                      placeholder="Enter"
                      name="mobileNo"
                      disabled={registrationDone}
                      maxLength={10}
                      {...register("mobileNo", {
                        required: "Please enter your Phone Number",
                        minLength: {
                          value: 10,
                          message: "Please enter 10 digits Phone Number",
                        },
                        onChange: (e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                          } else {
                            setValue(
                              "mobileNo",
                              e.target.value.replace(/\D/g, "")
                            );
                          }

                          if (
                            e.target.value.length === 10 &&
                            !isNaN(e.target.value)
                          ) {
                            setPhoneNumber(e.target.value);
                            setDisableOTP(false);
                          } else {
                            setDisableOTP(true);
                          }

                          let mobileInput =
                            document.getElementById("mobileInput");
                          if (e.target.value.length > 0) {
                            if (isMobile) {
                              mobileInput.style.paddingLeft = "7vw";
                            } else {
                              mobileInput.style.paddingLeft = "2.5vw";
                            }
                            setIsCountryCode(true);
                          } else {
                            if (isMobile) {
                              mobileInput.style.paddingLeft = "1.5vw";
                            } else {
                              mobileInput.style.paddingLeft = "0.4vw";
                            }
                            setIsCountryCode(false);
                          }
                        },
                      })}
                    />
                    {isCountryCode && (
                      <span className={styles.countryCode}>+91</span>
                    )}
                  </div>
                  {errors.mobileNo && (
                    <div className={styles.errorMsg}>
                      * {errors.mobileNo.message}
                    </div>
                  )}
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.inputLabel}>Email ID</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="email"
                      disabled={registrationDone}
                      {...register("email")}
                    />
                  </div>
                </div>

                {registrationDone && (
                  <div className={styles.formGroup}>
                    <div className={styles.inputLabel}>OTP *</div>
                    <div className={styles.otpContainer}>
                      <div className={styles.otpInput}>
                        <input
                          type="text"
                          placeholder="Enter"
                          name="otp"
                          {...register("otp", {
                            required: "Please enter OTP",
                            onChange: (e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                              } else {
                                setValue(
                                  "otp",
                                  e.target.value.replace(/\D/g, "")
                                );
                              }
                            },
                          })}
                        />
                      </div>
                      <div className={styles.otpButton}>
                        <button
                          onClick={() => sendOTP()}
                          type="button"
                          disabled={disableOTP}
                        >
                          {otpStatus}
                        </button>
                      </div>
                    </div>
                    {errors.otp && (
                      <div className={styles.errorMsg}>
                        * {errors.otp.message}
                      </div>
                    )}
                  </div>
                )}

                <div className={styles.formGroup}>
                  <div className={styles.agreement}>
                    <div>
                      <input
                        type="checkbox"
                        name="agreement"
                        {...register("agreement", {
                          required:
                            "Please accept this agreement and then submit",
                        })}
                      />
                    </div>
                    <div className={styles.agreementIns}>
                      By checking this box you agree to the{" "}
                      <Link
                        to={"/privacy"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b>Privacy Policy</b>
                      </Link>
                    </div>
                  </div>
                  {errors.agreement && (
                    <div className={styles.errorMsg}>
                      * {errors.agreement.message}
                    </div>
                  )}
                </div>
                <div className={styles.formGroup}>
                  <button disabled={isSubmitting}>{submitValue}</button>
                </div>
              </form>
              <div className={styles.footerContainer}>
                <div>
                  Already have an account? <Link to={"/login"}>Log in</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
