import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { Country, State } from "country-state-city";
import { toast } from "react-toastify";
import axios from "axios";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { useCookies } from "react-cookie";
import {
  setCurrentUser,
  getCurrentUser,
  removeCurrentUser,
} from "../../services/authServices";
import styles from "./login.module.css";
import motoverseImg from "../../images/motoverse.png";
import tvsImg from "../../images/tvs.png";
import wickedImg from "../../images/wicked.png";

const Login = () => {
  const navigate = useNavigate();
  const [submitValue, setSubmitValue] = useState("Submit");
  const [disableOTP, setDisableOTP] = useState(true);
  const [otpStatus, setOTPStatus] = useState("Get OTP");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isCountryCode, setIsCountryCode] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);

  useEffect(() => {
    // setCountryList(Country.getAllCountries());
    // ReactGA.initialize("UA-245656363-1");
    // ReactGA.pageview(window.location.pathname + window.location.search);

    // const advancedMatching = { em: "" };
    // const options = {
    //   autoConfig: true,
    //   debug: false,
    // };
    // ReactPixel.init("1124829078045398", advancedMatching, options);
    // ReactPixel.pageView();
    removeCurrentUser();
    localStorage.removeItem("firstTimeuser");
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const sendOTP = () => {
    setDisableOTP(true);
    setOTPStatus("Processing");
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/sendOTPToUser",
        {
          mobileNo: phoneNumber,
        },
        {
          headers: {
            "moto-x-request-header":
              "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          setOTPStatus("Get OTP");
          setDisableOTP(false);
        }, 10000);
        setOTPStatus("✓ Sent");
      })
      .catch((error) => {
        setOTPStatus("Get OTP");
        setDisableOTP(false);
        toast.error("Sorry! You're not registered with us.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    setSubmitValue("Please wait...");
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/participantLogin", data, {
        headers: {
          "moto-x-request-header":
            "PeiEghQOzaSQurkr89abyIm9JmEQ5Zk_oa16pXXKyGM",
        },
      })
      .then((response) => {
        setCurrentUser(response.data.userData);
        navigate("/dashboard");
        toast.success("You have successfully logged in", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });

        window.dataLayer.push({
          event: "login_submit",
          eventCategory: "Login",
          eventAction: "Submit",
          eventLabel: "success",
          "User ID": response.data.userData._id,
        });

        setCookie("name", response.data.userData.name, { path: "/login" });
        setCookie("mobile", response.data.userData.mobileNo, {
          path: "/login",
        });
        setCookie("email", response.data.userData.email, { path: "/login" });
      })
      .catch((error) => {
        if (error.response.status === 417) {
          toast.error("Sorry! Invalid OTP", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          window.dataLayer.push({
            event: "login_submit",
            eventCategory: "Login",
            eventAction: "Submit",
            eventLabel: "fail",
            "error message": "Sorry! Invalid OTP",
          });
        } else if (error.response.status === 401) {
          toast.error("Sorry! You're not registered with us.", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          window.dataLayer.push({
            event: "login_submit",
            eventCategory: "Login",
            eventAction: "Submit",
            eventLabel: "fail",
            "error message": "Sorry! You're not registered with us.",
          });
        }
        setIsSubmitting(false);
        setSubmitValue("Submit");
      });
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.tvsContainer}>
        <img src={tvsImg} alt="TVS" />
      </div>
      <div className={styles.wickedContainer}>
        <img src={wickedImg} alt="Wicked" />
      </div>
      <div className={styles.boxContainer}>
        <div>
          <div className={styles.motoverseContainer}>
            <img src={motoverseImg} alt="Motoverse" />
          </div>
          <div className={styles.formContainer}>
            <div className={styles.switchContainer}>
              <div
                className={styles.switchBoxDisabled}
                onClick={() => navigate("/")}
              >
                REGISTER
              </div>
              <div className={styles.switchBox}>LOGIN</div>
            </div>
            <div className={styles.formBox}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* <div className={styles.formGroup}>
                  <div className={styles.inputLabel}>Phone Number</div>
                  <div className={styles.postionRelative}>
                    <input
                      id="mobileInput"
                      type="text"
                      placeholder="Enter"
                      name="mobileNo"
                      maxLength={10}
                      {...register("mobileNo", {
                        required: "Please enter your Phone Number",
                        minLength: {
                          value: 10,
                          message: "Please enter 10 digits Phone Number",
                        },
                        onChange: (e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                          } else {
                            setValue(
                              "mobileNo",
                              e.target.value.replace(/\D/g, "")
                            );
                          }

                          if (
                            e.target.value.length === 10 &&
                            !isNaN(e.target.value)
                          ) {
                            setPhoneNumber(e.target.value);
                            setDisableOTP(false);
                          } else {
                            setDisableOTP(true);
                          }

                          let mobileInput =
                            document.getElementById("mobileInput");
                          if (e.target.value.length > 0) {
                            if (isMobile) {
                              mobileInput.style.paddingLeft = "7vw";
                            } else {
                              mobileInput.style.paddingLeft = "2.5vw";
                            }
                            setIsCountryCode(true);
                          } else {
                            if (isMobile) {
                              mobileInput.style.paddingLeft = "1.5vw";
                            } else {
                              mobileInput.style.paddingLeft = "0.4vw";
                            }
                            setIsCountryCode(false);
                          }
                        },
                      })}
                    />
                    {isCountryCode && (
                      <span className={styles.countryCode}>+91</span>
                    )}
                  </div>
                  {errors.mobileNo && (
                    <div className={styles.errorMsg}>
                      * {errors.mobileNo.message}
                    </div>
                  )}
                </div> */}
                <div className={styles.formGroup}>
                  <div className={styles.inputLabel}>Phone Number</div>

                  <div className={styles.otpContainer}>
                    <div className={styles.otpInput}>
                      <input
                        id="mobileInput"
                        type="text"
                        placeholder="Enter"
                        name="mobileNo"
                        maxLength={10}
                        {...register("mobileNo", {
                          required: "Please enter your Phone Number",
                          onChange: (e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                            } else {
                              setValue(
                                "mobileNo",
                                e.target.value.replace(/\D/g, "")
                              );
                            }

                            if (
                              e.target.value.length === 10 &&
                              !isNaN(e.target.value)
                            ) {
                              setPhoneNumber(e.target.value);
                              setDisableOTP(false);
                            } else {
                              setDisableOTP(true);
                            }

                            let mobileInput =
                              document.getElementById("mobileInput");
                            if (e.target.value.length > 0) {
                              if (isMobile) {
                                mobileInput.style.paddingLeft = "7vw";
                              } else {
                                mobileInput.style.paddingLeft = "2.5vw";
                              }
                              setIsCountryCode(true);
                            } else {
                              if (isMobile) {
                                mobileInput.style.paddingLeft = "1.5vw";
                              } else {
                                mobileInput.style.paddingLeft = "0.4vw";
                              }
                              setIsCountryCode(false);
                            }
                          },
                        })}
                      />
                      {isCountryCode && (
                        <span className={styles.countryCode}>+91</span>
                      )}
                    </div>
                    <div className={styles.otpButton}>
                      <button
                        onClick={() => sendOTP()}
                        type="button"
                        disabled={disableOTP}
                      >
                        {otpStatus}
                      </button>
                    </div>
                  </div>
                  {errors.mobileNo && (
                    <div className={styles.errorMsg}>
                      * {errors.mobileNo.message}
                    </div>
                  )}
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.inputLabel}>OTP</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter"
                      name="otp"
                      maxLength={6}
                      {...register("otp", {
                        required: "Please enter OTP",
                        onChange: (e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                          } else {
                            setValue("otp", e.target.value.replace(/\D/g, ""));
                          }
                        },
                      })}
                    />
                  </div>
                  {errors.otp && (
                    <div className={styles.errorMsg}>
                      * {errors.otp.message}
                    </div>
                  )}
                </div>
                <div className={styles.formGroup}>
                  <button type="submit">{submitValue}</button>
                </div>
              </form>
              <div className={styles.footerContainer}>
                <div>
                  Don’t have an account? <Link to={"/"}>Register</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
